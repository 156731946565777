import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import PopupsForm from "@/forms/PopupsForm";
import { usePopupsContext } from "@/store";

type Props = {
  active: string;
  content: string;
  link: string;
};

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

export default () => {
  const [initPopup, setInitPopup] = useState(false);
  const { state, ...actions } = usePopupsContext();
  const history = useHistory();
  let { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    actions.fetchById(slug);
    setInitPopup(!initPopup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async ({ active, content, link }: Props) => {
    const activeBoolean = active === "true" ? true : false;

    actions
      .update(state.popupDetail.id, { active: activeBoolean, content, link })
      .then(() => {
        window.alert("수정되었습니다.");
        history.push("/popups");
      })
      .catch((error) => {
        window.alert(error.message);
      });
  };

  const onDelete = useCallback(() => {
    if (window.confirm("삭제하시겠습니까?")) {
      actions
        .deletePopup(state.popupDetail.id)
        .then(() => {
          window.alert("삭제되었습니다.");
          history.push("/popups");
        })
        .catch((error) => {
          window.alert(error.message);
        });
    }
  }, [actions, history, state.popupDetail.id]);

  const onDeleteImage = useCallback(() => {
    actions
      .update(state.popupDetail.id, {
        active: state.popupDetail.active,
        content: state.popupDetail.content,
        image: null,
        link: state.popupDetail.link,
      })
      .then(() => {
        actions.fetchById(slug);
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }, [actions, slug, state.popupDetail]);

  return (
    <Container>
      <Row>
        <Title>로그인 후 팝업</Title>
      </Row>
      <Card>
        <Card.Header as="h5">팝업 상세보기 및 수정</Card.Header>
        <Card.Body>
          <PopupsForm
            onDelete={onDelete}
            onDeleteImage={onDeleteImage}
            onSubmit={onSubmit}
            initPopup={initPopup}
          />
        </Card.Body>
        <Card.Footer>
          <Link to="/popups">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
