import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { useNoticesContext } from "@/store";
import NoticesForm from "@/forms/NoticesForm";

type Props = {
  title: string;
  content: string;
};

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

export default () => {
  const [initNotice, setInitNotice] = useState(false);
  const { state, ...actions } = useNoticesContext();
  const history = useHistory();
  let { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    actions.fetchById(slug);
    setInitNotice(!initNotice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async ({ title, content }: Props) => {
    actions
      .update(state.noticeDetail.id, { title, content })
      .then(() => {
        window.alert("수정되었습니다.");
        history.push("/notices");
      })
      .catch((error) => {
        window.alert(error.message);
      });
  };

  return (
    <Container>
      <Row>
        <Title>공지사항</Title>
      </Row>
      <Card>
        <Card.Header as="h5">공지사항 상세보기 및 수정</Card.Header>
        <Card.Body>
          <NoticesForm onSubmit={onSubmit} initNotice={initNotice} />
        </Card.Body>
        <Card.Footer>
          <Link to="/notices">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
