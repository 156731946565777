import React, { memo, useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { ImageUploadModal } from "@/Modals";
import { useNoAuthPopupsContext, useModalStore } from "@/store";
import { formatDate, getDateByTimeZone } from "@/lib/date";

const ActiveContainer = styled(Container)`
  margin-bottom: 15px;
`;

const ButtonsRow = styled(Row)`
  padding: 0 15px;
  display: inline-block;
  text-align: right;
`;

const CreatedAtContainer = styled(Container)`
  margin-bottom: 15px;
`;

const Img = styled.img`
  height: 370px;
  width: 100%;
  object-fit: contain;
`;

const ImgBox = styled.div`
  border: 1px #ced4da solid;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
`;

const ImgButton = styled(Button)`
  display: inline-block;
  width: 200px;
  margin-top: 0.25em;
`;

const ImageContainer = styled(Container)`
  position: relative;
  padding: 0;
`;

const ImageLink = styled.span`
  margin: 10px 0 15px;
`;

const ImageRow = styled(Row)`
  flex-direction: column;
  padding: 0 15px;
  margin-bottom: 15px;
`;

const SaveButton = styled(Button)`
  margin-right: 10px;
`;

type FormData = {
  active?: null | string;
  content: string;
  link?: null | string;
};

type Props = {
  onDelete?: any;
  onDeleteImage?: any;
  onSubmit?: any;
  initNoAuthPopup?: boolean;
};

export default ({
  onDelete,
  onDeleteImage,
  onSubmit,
  initNoAuthPopup,
}: Props) => {
  const { reset, register, handleSubmit } = useForm<FormData>();
  const { state } = useNoAuthPopupsContext();
  const { closeModal, openModal } = useModalStore();

  useEffect(() => {
    if (initNoAuthPopup) {
      reset({
        active: state.noAuthPopupDetail.active ? "true" : "false",
        content: state.noAuthPopupDetail.content,
        link: state.noAuthPopupDetail.link,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.noAuthPopupDetail]);

  const EditFooter = memo(() => {
    const handleClickDelete = () => {
      onDelete();
    };

    return (
      <div>
        <SaveButton type="submit">저장</SaveButton>
        <Button onClick={handleClickDelete}>삭제</Button>
      </div>
    );
  });

  const createdAt = formatDate(
    getDateByTimeZone(state.noAuthPopupDetail.createdAt),
    "yyyy/MM/dd HH:mm"
  );

  const [url, setUrl] = useState("");

  const handleImageUpload = useCallback(
    (binary) => {
      const encodedBase64 = btoa(binary);
      const imageData = "data:image/jpeg;base64," + encodedBase64;

      setUrl(imageData);
      closeModal();
    },
    [closeModal, setUrl]
  );

  const handleClickUploadImage = useCallback(() => {
    openModal(
      <ImageUploadModal
        dropzoneProps={{
          accept: "image/*",
          onUpload: handleImageUpload,
        }}
        key="popup-image-upload-modal"
        title="이미지 업로드"
      />
    );
  }, [openModal, handleImageUpload]);

  const handleClickDeleteImage = () => {
    onDeleteImage();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <ImageContainer>
            {!initNoAuthPopup && (
              <ImageRow>
                <strong>이미지</strong>

                <ImgButton onClick={handleClickUploadImage}>
                  이미지 업로드
                </ImgButton>
                {url && (
                  <ImgBox>
                    <Img src={url} />
                  </ImgBox>
                )}
              </ImageRow>
            )}

            {initNoAuthPopup && state.noAuthPopupDetail.image && (
              <ImageRow>
                <strong>이미지</strong>

                <ButtonsRow>
                  <Button onClick={handleClickUploadImage}>수정</Button>{" "}
                  <Button onClick={handleClickDeleteImage}>삭제</Button>
                </ButtonsRow>
                <ImgBox>
                  <Img src={url ? url : state.noAuthPopupDetail.image} />
                </ImgBox>
                {!url && state.noAuthPopupDetail.image && (
                  <ImageLink>
                    링크:{" "}
                    <a
                      href={state.noAuthPopupDetail.image}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {state.noAuthPopupDetail.image}
                    </a>
                  </ImageLink>
                )}
              </ImageRow>
            )}
          </ImageContainer>

          <Form.Group>
            <Form.Label>
              <strong>내용</strong>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={15}
              name="content"
              ref={register}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>
              <strong>링크</strong>
            </Form.Label>
            <Form.Control name="link" ref={register} />
          </Form.Group>
          <ActiveContainer>
            <Row>
              <strong>공개여부</strong>
            </Row>
            <Row>
              <Form.Check
                inline
                label="공개"
                type="radio"
                name="active"
                id="inline-checkbox-1"
                value="true"
                defaultChecked
                ref={register}
              />
              <Form.Check
                inline
                label="비공개"
                type="radio"
                name="active"
                value="false"
                id="inline-checkbox-2"
                ref={register}
              />
            </Row>
          </ActiveContainer>

          {initNoAuthPopup && (
            <CreatedAtContainer>
              <Row>
                <strong>등록 일시</strong>
              </Row>
              <Row>{createdAt}</Row>
            </CreatedAtContainer>
          )}
        </Col>
      </Row>
      {initNoAuthPopup ? <EditFooter /> : <Button type="submit">추가</Button>}
    </Form>
  );
};
