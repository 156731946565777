import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from "react";
import ky from "ky";
import _remove from "lodash/remove";

import { Popups } from "@/Models/Popups";

export type FormData = {
  active?: null | boolean;
  content: string | undefined;
  image?: null | string;
  link?: null | string;
};

export type NoAuthPopups = {
  active: null | boolean;
  content?: string;
  createdAt: undefined | Date;
  id: null | number;
  image?: undefined | string;
  link?: null | string;
  updatedAt?: undefined | Date;
};

enum ACTION_TYPES {
  CREATE = "CREATE",
  DELETE = "DELETE",
  FETCH_ALL = "FETCH_ALL",
  FETCH_BY_ID = "FETCH_BY_ID",
  SET_QUERY = "SET_QUERY",
  UPDATE = "UPDATE",
}

type Action =
  | { type: ACTION_TYPES.CREATE }
  | { type: ACTION_TYPES.DELETE; id: number }
  | {
      type: ACTION_TYPES.FETCH_ALL;
      noAuthPopups: NoAuthPopups[];
      pageCount: number;
    }
  | { type: ACTION_TYPES.FETCH_BY_ID; noAuthPopupDetail: NoAuthPopups }
  | { type: ACTION_TYPES.SET_QUERY; query: Query }
  | { type: ACTION_TYPES.UPDATE };

type Query = {
  page: number;
  pageSize: number;
};

interface State {
  noAuthPopups: NoAuthPopups[];
  noAuthPopupDetail: NoAuthPopups;
  pageCount: number;
  query: Query;
}

const INITIAL_STATE: State = {
  noAuthPopups: [],
  noAuthPopupDetail: {
    active: null,
    content: "",
    createdAt: undefined,
    id: null,
    image: undefined,
    link: null,
    updatedAt: undefined,
  },
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
  },
};

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.CREATE:
      return {
        ...prevState,
      };
    case ACTION_TYPES.DELETE:
      return {
        ...prevState,
        noAuthPopups: _remove(
          prevState.noAuthPopups,
          (noAuthPopups) => noAuthPopups.id !== action.id
        ),
      };
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...prevState,
        noAuthPopups: action.noAuthPopups,
        pageCount: action.pageCount,
      };
    case ACTION_TYPES.FETCH_BY_ID:
      return {
        ...prevState,
        noAuthPopupDetail: action.noAuthPopupDetail,
      };
    case ACTION_TYPES.SET_QUERY:
      return {
        ...prevState,
        query: {
          ...action.query,
        },
      };
    case ACTION_TYPES.UPDATE:
      return {
        ...prevState,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext<{
  state: typeof INITIAL_STATE;
  dispatch: (action: Action) => void;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useNoAuthPopupsContext = () => {
  const { state, dispatch } = useContext(Context);

  const create = useCallback(({ active, content, link }: FormData) => {
    return ky.post(`${process.env.REACT_APP_API_HOST}/no-auth-popups`, {
      json: { active, content, link },
      credentials: "include",
    });
  }, []);

  const deleteNoAuthPopup = useCallback((id) => {
    return ky.delete(`${process.env.REACT_APP_API_HOST}/no-auth-popups/${id}`, {
      credentials: "include",
    });
  }, []);

  const fetchAll = useCallback(async () => {
    try {
      const response = await ky.get(
        `${process.env.REACT_APP_API_HOST}/no-auth-popups`,
        {
          searchParams: {
            page: state.query.page,
            pageSize: state.query.pageSize,
          },
          credentials: "include",
        }
      );
      const { noAuthPopups, pageCount } = await response.json();

      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        noAuthPopups: noAuthPopups.map((p: NoAuthPopups) => new Popups(p)),
        pageCount,
      });
    } catch (e) {
      throw await e.response.json();
    }
  }, [dispatch, state.query]);

  const fetchById = useCallback(
    async (id) => {
      try {
        const response = await ky.get(
          `${process.env.REACT_APP_API_HOST}/no-auth-popups/${id}`,
          {
            credentials: "include",
          }
        );
        const noAuthPopupDetail = await response.json();

        dispatch({
          type: ACTION_TYPES.FETCH_BY_ID,
          noAuthPopupDetail,
        });
      } catch (e) {
        throw await e.response.json();
      }
    },
    [dispatch]
  );

  const setQuery = useCallback(
    (query: { page?: number; pageSize?: number }) => {
      try {
        dispatch({
          type: ACTION_TYPES.SET_QUERY,
          query: {
            ...state.query,
            ...query,
          },
        });
      } catch (e) {
        throw e;
      }
    },
    [dispatch, state.query]
  );

  const update = useCallback(
    (id, { active, content, image, link }: FormData) => {
      return ky.put(`${process.env.REACT_APP_API_HOST}/no-auth-popups/${id}`, {
        json: { active, content, image, link },
        credentials: "include",
      });
    },
    []
  );

  return {
    state,
    create,
    deleteNoAuthPopup,
    fetchAll,
    fetchById,
    setQuery,
    update,
  };
};
