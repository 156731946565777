/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import OriginContainer from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Column } from "react-table";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import TableComponent from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useUsersContext } from "@/store";

const Container = styled(OriginContainer)`
  max-width: 1300px;
`;

const ResetButton = styled(Button)`
  margin-left: 5px;
`;

const RefreshButton = styled(Button)`
  text-align: right;
  height: 40px;
`;

const SearchRow = styled(Row)`
  margin-top: 15px;
`;

const SearchButton = styled(Button)`
  margin-left: 10px;
`;

const SearchCol = styled(Col)`
  display: inline-block;
  padding: 0;
`;

const SearchForm = styled(Form)`
  width: calc(100% - 130px);
`;

const Title = styled.h1`
  padding-left: 15px;
`;

const UsersTable = styled(TableComponent)``;

const Users = () => {
  const { handleSubmit, register, setValue } = useForm();
  const { state, ...actions } = useUsersContext();

  useEffect(() => {
    setValue("contact", state.query.contact);
    setValue("email", state.query.email);
    actions.fetchAll();
  }, []);

  useEffect(() => {
    actions.fetchAll();
  }, [state.query]);

  const handleClear = useCallback(() => {
    actions.resetQuery();
    setValue("contact", "");
    setValue("email", "");
  }, [actions.resetQuery]);

  const handleClickRefresh = useCallback(() => {
    actions.clear();
    actions.setQuery({
      page: state.query.page,
      pageSize: state.query.pageSize,
    });
    actions.fetchAll();
  }, [actions.clear, actions.fetchAll, state.query]);

  const handleGoToPage = useCallback(
    (page: number) => {
      actions.setQuery({ page });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const onSubmit = useCallback(
    ({ contact, email }) => {
      if (state.query.page === 1) {
        actions.setQuery({
          contact,
          email,
        });
      } else {
        actions.setQuery({
          contact,
          email,
          page: 1,
        });
      }
    },
    [actions.setQuery]
  );

  let columns: Column<any>[] = [
    {
      Header: "번호",
      accessor: "id",
      id: "id",
    },
    {
      Header: "이메일",
      accessor: (row) => <Link to={`/users/${row.id}`}>{row.email}</Link>,
      id: "title",
    },
    {
      Header: "연락처",
      accessor: (row) => row.contact,
    },
    {
      Header: "이름",
      accessor: (row) => row.name,
    },
    {
      Header: "가입일",
      accessor: (row) =>
        formatDate(getDateByTimeZone(row.createdAt), "yyyy/MM/dd HH:mm"),
    },
    {
      Header: "로그인 일시",
      accessor: (row) =>
        row.loggedAt === null
          ? ""
          : formatDate(getDateByTimeZone(row.loggedAt), "yyyy/MM/dd HH:mm"),
    },
    {
      Header: "탈퇴 여부",
      accessor: (row) => (row.deleted ? "탈퇴" : ""),
    },
    {
      Header: "탈퇴일",
      accessor: (row) =>
        row.deletedAt === null
          ? ""
          : formatDate(getDateByTimeZone(row.deletedAt), "yyyy/MM/dd HH:mm"),
    },
  ];

  return (
    <Container>
      <Row>
        <Title>회원 조회</Title>
      </Row>
      <SearchRow>
        <SearchForm onSubmit={handleSubmit(onSubmit)}>
          <SearchCol md={4}>
            <Form.Label column sm={3}>
              이메일
            </Form.Label>
            <SearchCol sm={9}>
              <Form.Group>
                <Form.Control name="email" ref={register} />
              </Form.Group>
            </SearchCol>
          </SearchCol>
          <SearchCol md={4}>
            <Form.Label column sm={3}>
              연락처
            </Form.Label>
            <SearchCol sm={9}>
              <Form.Group>
                <Form.Control name="contact" ref={register} />
              </Form.Group>
            </SearchCol>
          </SearchCol>
          <SearchCol md={{ span: 2 }}>
            <SearchButton type="submit">검색하기</SearchButton>
            <ResetButton variant="danger" onClick={handleClear}>
              초기화
            </ResetButton>
          </SearchCol>
        </SearchForm>
        <RefreshButton variant="info" onClick={handleClickRefresh}>
          목록 새로고침
        </RefreshButton>
      </SearchRow>
      <Row>
        <Col>
          <UsersTable
            columns={columns}
            currentPage={state.query.page}
            data={state.users}
            gotoPage={handleGoToPage}
            nextPage={handleNextPage}
            pageSize={state.query.pageSize}
            pageCount={state.pageCount}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
