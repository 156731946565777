import React from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { useNoticesContext } from "@/store";
import NoticesForm from "@/forms/NoticesForm";

type Props = {
  title: string;
  content: string;
};

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

export default () => {
  const { state, ...actions } = useNoticesContext();
  const history = useHistory();

  const onSubmit = async ({ title, content }: Props) => {
    actions
      .create({ title, content })
      .then(() => {
        window.alert("추가되었습니다.");
        history.push("/notices");
      })
      .catch((error) => {
        window.alert(error.message);
      });
  };

  return (
    <Container>
      <Row>
        <Title>공지사항</Title>
      </Row>
      <Card>
        <Card.Header as="h5">공지사항 생성</Card.Header>
        <Card.Body>
          <NoticesForm onSubmit={onSubmit} />
        </Card.Body>
        <Card.Footer>
          <Link to="/notices">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
