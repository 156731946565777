import React, { memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { useNoticesContext } from "@/store";
import { formatDate, getDateByTimeZone } from "@/lib/date";

const CreatedAtContainer = styled(Container)`
  margin-bottom: 15px;
`;

const SaveButton = styled(Button)`
  margin-right: 10px;
`;

type FormData = {
  title: string;
  content: string;
};

type Props = {
  onSubmit: ({ title, content }: FormData) => Promise<void>;
  initNotice?: boolean;
};

export default ({ onSubmit, initNotice }: Props) => {
  const { reset, register, handleSubmit } = useForm<FormData>();
  const { state, ...actions } = useNoticesContext();
  const history = useHistory();

  useEffect(() => {
    if (initNotice) {
      reset({
        title: state.noticeDetail.title,
        content: state.noticeDetail.content,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.noticeDetail]);

  const EditFooter = memo(() => {
    const handleClickDelete = () => {
      if (window.confirm("삭제하시겠습니까?")) {
        actions.deleteNotice(state.noticeDetail.id);
        history.push("/notices");
      }
    };

    return (
      <div>
        <SaveButton type="submit">저장</SaveButton>
        <Button onClick={handleClickDelete}>삭제</Button>
      </div>
    );
  });

  const createdAt = formatDate(
    getDateByTimeZone(state.noticeDetail.createdAt),
    "yyyy/MM/dd HH:mm"
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>
              <strong>공지사항 제목</strong>
            </Form.Label>
            <Form.Control name="title" ref={register} />
          </Form.Group>
          {initNotice && (
            <CreatedAtContainer>
              <Row>
                <strong>작성 일시</strong>
              </Row>
              <Row>{createdAt}</Row>
            </CreatedAtContainer>
          )}
          <Form.Group>
            <Form.Label>
              <strong>공지사항 내용</strong>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={15}
              name="content"
              ref={register}
            />
          </Form.Group>
        </Col>
      </Row>
      {initNotice ? <EditFooter /> : <Button type="submit">추가</Button>}
    </Form>
  );
};
