import React from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { usePopupsContext } from "@/store";
import PopupsForm from "@/forms/PopupsForm";

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

type Props = {
  active: string;
  content: string;
  link: string;
};

export default () => {
  const { state, ...actions } = usePopupsContext();
  const history = useHistory();

  const onSubmit = async ({ active, content, link }: Props) => {
    const activeBoolean = active === "true" ? true : false;

    actions
      .create({ active: activeBoolean, content, link })
      .then(() => {
        window.alert("추가되었습니다.");
        history.push("/popups");
      })
      .catch((error) => {
        window.alert(error.message);
      });
  };

  return (
    <Container>
      <Row>
        <Title>팝업 생성</Title>
      </Row>
      <Card>
        <Card.Header as="h5">로그인 후 팝업 생성</Card.Header>
        <Card.Body>
          <PopupsForm onSubmit={onSubmit} />
        </Card.Body>
        <Card.Footer>
          <Link to="/popups">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
