import { formatDate, getDateByTimeZone } from "@/lib/date";

export const DELIVERY_STATUSES = {
  PAYMENT_WAITING: 'PAYMENT_WAITING',
  RESERVED: 'RESERVED',
  PRINTED: 'PRINTED',
  CONFIRMED: 'CONFIRMED',
  READY: 'READY',
  PICKUP: 'PICKUP',
  ARRIVED: 'ARRIVED',
  DELIVERED: 'DELIVERED',
  RECEIPTED: 'RECEIPTED',
  GATHERED: 'GATHERED',
  CANCELLED: 'CANCELLED',
  RETURNED: 'RETURNED',
};

export const TYPE_CUGS = 'cugs';

export const TYPE_WITH_PRICETYPE_CUGS = 'cugs';
export const TYPE_WITH_PRICETYPE_GSHALF = 'gshalf';

export const PRICE_TYPE_NORMAL = 'normal';
export const PRICE_TYPE_HALF = 'half';

export class Delivery {
  arrivedAt = undefined;
  category = "";
  cancelledAt = undefined;
  createdAt = undefined;
  deliveredAt = undefined;
  id = null;
  gatheredAt = undefined;
  goodsName = "";
  memo = "";
  paidAt = undefined;
  pickedUpAt = undefined;
  prepaid = null;
  price = 0;
  priceType = "";
  receiverAddress = {
    receiverName: "",
    receiverMobile: "",
    postCode: "",
    address: "",
    addressDetail: "",
  };
  receiverName = "";
  receiverMobile = "";
  receiptedAt = undefined;
  reservationNumber = "";
  returnedAt = undefined;
  status = DELIVERY_STATUSES.PAYMENT_WAITING;
  senderName = "";
  senderMobile = "";
  senderPostCode = "";
  senderAddress = "";
  senderAddressDetail = "";
  trackingNumber = "";
  type = "";
  user = {
    name: "",
    contact: "",
    email: "",
  };
  userContact = "";
  userEmail = "";
  userId = null;
  etc = "";

  constructor(delivery: any) {
    this.arrivedAt = delivery.arrivedAt;
    this.category = delivery.category;
    this.cancelledAt = delivery.cancelledAt;
    this.createdAt = delivery.createdAt;
    this.deliveredAt = delivery.deliveredAt;
    this.id = delivery.id;    
    this.gatheredAt = delivery.gatheredAt;
    this.goodsName = delivery.goodsName;
    this.memo = delivery.memo;
    this.paidAt = delivery.paidAt;
    this.pickedUpAt = delivery.pickedUpAt;
    this.prepaid = delivery.prepaid;
    this.price = delivery.price;
    this.priceType = delivery.priceType;
    this.receiverAddress = delivery.receiverAddress;
    this.receiverName =
      delivery.receiverAddress && delivery.receiverAddress.receiverName;
    this.receiverMobile =
      delivery.receiverAddress && delivery.receiverAddress.receiverMobile;
    this.receiptedAt = delivery.receiptedAt;
    this.reservationNumber = delivery.reservationNumber;
    this.returnedAt = delivery.returnedAt;
    this.status = delivery.status;
    this.senderName = delivery.senderName;
    this.senderMobile = delivery.senderMobile;
    this.senderPostCode = delivery.senderPostCode;
    this.senderAddress = delivery.senderAddress;
    this.senderPostCode = delivery.senderPostCode;
    this.senderAddressDetail = delivery.senderAddressDetail;
    this.trackingNumber = delivery.trackingNumber;
    this.type = delivery.type;
    this.user = delivery.user;
    this.userContact = delivery.user && delivery.user.contact;
    this.userEmail = delivery.user && delivery.user.email;
    this.userId = delivery.userId;
    this.etc = delivery.etc;
  }

  get etcObj() {
    if (this.etc) {
      return JSON.parse(this.etc);
    } else {
      return {};
    }
  }

  get receiverNameContact() {
    return this.receiverMobile
      ? `${this.receiverName} (${this.receiverMobile})`
      : this.receiverName;
  }

  get senderNameContact() {
    return this.senderMobile
      ? `${this.senderName} (${this.senderMobile})`
      : this.senderName;
  }

  get userContactEmail() {
    return this.userContact
      ? `${this.userEmail} (${this.userContact})`
      : this.userEmail;
  }

  renderCreatedAt(dateFormat = "yyyy/MM/dd HH:mm") {
    return (
      this.createdAt &&
      formatDate(getDateByTimeZone(this.createdAt), dateFormat)
    );
  }

  renderStatus() {
    switch (this.status) {
      case DELIVERY_STATUSES.PAYMENT_WAITING:
      case DELIVERY_STATUSES.RESERVED:
      case DELIVERY_STATUSES.PRINTED:
        return '접수전';
      case DELIVERY_STATUSES.CONFIRMED:
      case DELIVERY_STATUSES.READY:
      case DELIVERY_STATUSES.RECEIPTED:
        return '배송준비';
      case DELIVERY_STATUSES.PICKUP:
      case DELIVERY_STATUSES.ARRIVED:
      case DELIVERY_STATUSES.GATHERED:
        return '배송중';
      case DELIVERY_STATUSES.DELIVERED:
        return '배송완료';
      case DELIVERY_STATUSES.CANCELLED:
        return '예약취소';
      case DELIVERY_STATUSES.RETURNED:
        return '반송완료';
      default:
        return '';
    }
  }

  renderTypeWithPriceType() {
    if (this.type === TYPE_CUGS) {
      if (this.priceType === PRICE_TYPE_HALF) {
        return '반값택배';
      } else {
        return '편의점택배';
      }
    } else {
      return '편의점택배';
    }
  }

  get typeWithPriceType() {
    if (this.type === TYPE_CUGS) {
      if (this.priceType === PRICE_TYPE_HALF) {
        return TYPE_WITH_PRICETYPE_GSHALF;
      } else {
        return TYPE_WITH_PRICETYPE_CUGS;
      }
    } else {
      return TYPE_WITH_PRICETYPE_CUGS;
    }
  }
}
