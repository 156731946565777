import { formatDate, getDateByTimeZone } from "@/lib/date";

export class Popups {
  active = null;
  content = "";
  createdAt = undefined;
  id = null;
  image = "";
  link = null;
  updatedAt = undefined;

  constructor(popups: any) {
    this.active = popups.active;
    this.content = popups.content;
    this.createdAt = popups.createdAt;
    this.id = popups.id;
    this.image = popups.image;
    this.link = popups.link;
    this.updatedAt = popups.updatedAt;
  }

  get activeStatus() {
    return this.active;
  }

  renderCreatedAt(dateFormat = "yyyy/MM/dd HH:mm") {
    return (
      this.createdAt &&
      formatDate(getDateByTimeZone(this.createdAt), dateFormat)
    );
  }
}
