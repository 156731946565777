import React from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import styled from "@emotion/styled";

const NavLi = styled.li`
  display: block;
  padding: 0.5rem 1rem;
`;

const NavLink = styled(Link)`
  padding-left: 20px;
`;

export default () => {
  return (
    <Nav defaultActiveKey="/home" className="flex-column">
      <NavLi>
        <Link to="/users">회원조회</Link>
      </NavLi>
      <NavLi>
        <Link to="/deliveries">택배 신청</Link>
      </NavLi>
      <NavLi>
        <Link to="/notices">공지 사항</Link>
      </NavLi>
      <NavDropdown title="팝업" id="nav-dropdown">
        <NavLink to="/no-auth-popups">로그인 전</NavLink>
        <NavDropdown.Divider />
        <NavLink to="/popups">로그인 후</NavLink>
      </NavDropdown>
      <NavLi>
        <Link to="/qnas">문의/제안</Link>
      </NavLi>
      <NavLi>
        <Link to="/strike">파업 관리</Link>
      </NavLi>
    </Nav>
  );
};
