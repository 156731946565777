import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useReducer,
} from "react";
import { useContext } from "react";

export type Errors = {
  humanMessage?: string;
  message?: string;
  status?: number;
};

enum ACTION_TYPES {
  API_ERROR = "API_ERROR",
  CLEAR = "CLEAR",
  DISMISS = "DISMISS",
}

type Action =
  | {
      type: ACTION_TYPES.API_ERROR;
      humanMessage: string;
      status: number;
      message: string;
    }
  | { type: ACTION_TYPES.CLEAR }
  | { type: ACTION_TYPES.DISMISS; index: number };

interface State {
  errors: Errors[];
}

const INITIAL_STATE: State = {
  errors: [],
};

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.API_ERROR:
      return {
        ...prevState,
        errors: [
          ...prevState.errors,
          {
            humanMessage: action.humanMessage,
            message: action.message,
            status: action.status,
          },
        ],
      };
    case ACTION_TYPES.CLEAR:
      return INITIAL_STATE;
    case ACTION_TYPES.DISMISS:
      return {
        ...prevState,
        errors: [
          ...prevState.errors.slice(0, action.index),
          ...prevState.errors.slice(action.index + 1),
        ],
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext<{
  state: typeof INITIAL_STATE;
  dispatch: (action: Action) => void;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useErrorStore = () => {
  const { state, dispatch } = useContext(Context);

  const apiError = useCallback(
    ({
      humanMessage,
      status,
      message,
    }: {
      humanMessage: string;
      status: number;
      message: string;
    }) => {
      dispatch({
        type: ACTION_TYPES.API_ERROR,
        humanMessage,
        message,
        status,
      });
    },
    [dispatch]
  );

  const clear = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR,
    });
  }, [dispatch]);

  const dismiss = useCallback(
    (index: number) => {
      dispatch({
        type: ACTION_TYPES.DISMISS,
        index,
      });
    },
    [dispatch]
  );

  return {
    state,
    apiError,
    clear,
    dismiss,
  };
};
