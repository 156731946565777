/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "@emotion/styled";

import Nav from "@/components/Nav";
import { useAuthStore, useLoadingStore, useModalStore } from "@/store";

import routes, {
  INDEX_PRIVATE_ROUTE_PATHNAME,
  INDEX_PUBLIC_ROUTE_PATHNAME,
} from "./routes";

const LoadingBackdrop = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const Page = styled(Container)`
  padding-top: ${({ theme }) => (theme as any).constants.NAV_HEIGHT};
`;

export default () => {
  const {
    checkAlreadySignIn,
    signInExistingUser,
    state: authStoreState,
  } = useAuthStore();

  const { state: loadingState } = useLoadingStore();
  const {
    state: { modals },
  } = useModalStore();

  const history = useHistory();
  const [fromReferer] = useState(
    history.location.pathname || INDEX_PRIVATE_ROUTE_PATHNAME
  );

  useEffect(() => {
    if (checkAlreadySignIn()) {
      signInExistingUser();
      history.push(fromReferer);
    } else {
      history.push(INDEX_PUBLIC_ROUTE_PATHNAME);
    }
  }, []);

  return (
    <>
      <Switch>
        {routes.map((route, index) => (
          <Route exact={route.exact} key={index} path={route.path}>
            {route.private && !authStoreState.user && (
              <Redirect to={INDEX_PUBLIC_ROUTE_PATHNAME} />
            )}

            {((route.private && authStoreState.user) || !route.private) && (
              <Page fluid={route.fluid}>
                <Row>
                  {route.nav && (
                    <Col md={1}>
                      <Nav />
                    </Col>
                  )}

                  <Col md={route.nav ? 11 : 12}>
                    <route.component />
                  </Col>
                </Row>
              </Page>
            )}
          </Route>
        ))}
      </Switch>

      {modals.map((modal) => modal)}

      {loadingState.loading && (
        <LoadingBackdrop>
          <Spinner animation="grow" />
        </LoadingBackdrop>
      )}
    </>
  );
};
