/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import OriginContainer from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Column } from "react-table";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import TableComponent from "@/components/Table";
import { PRICE_TYPE_NORMAL, PRICE_TYPE_HALF } from "@/Models/Delivery";
import { useDeliveriesContext } from "@/store";

const Container = styled(OriginContainer)`
  max-width: 1300px;
`;

const DeliveriesTable = styled(TableComponent)``;

const FirstRow = styled(Row)`
  margin-right: 0;
  margin-left: 0;
`;

const RefreshButton = styled(Button)`
  text-align: right;
  height: 40px;
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

const ResetButton = styled(Button)`
  margin-left: 5px;
`;

const SearchButton = styled(Button)`
  margin-left: 15px;
`;

const SearchCol = styled(Col)`
  display: inline-block;
  padding: 0;
`;

const SearchForm = styled(Form)`
  width: calc(100% - 130px);
`;

const SearchRow = styled(Row)`
  margin-top: 15px;
  position: relative;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

const UserLink = styled(Link)`
  color: #121212;
  text-decoration: underline;

  &: hover {
    color: #121212;
    text-decoration: underline;
  }
`;

const Deliveries = () => {
  const { handleSubmit } = useForm();
  const { state, ...actions } = useDeliveriesContext();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  useEffect(() => {
    actions.fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({ [e.target.name]: e.target.value });
    },
    [actions.setQuery]
  );

  const handleClear = useCallback(() => {
    actions.resetQuery();
    actions.fetchAll();
  }, [actions.resetQuery]);

  const handleClickRefresh = useCallback(() => {
    actions.clear();
    actions.setQuery({
      page: state.query.page,
      pageSize: state.query.pageSize,
    });
    actions.fetchAll();
  }, [actions.clear, actions.fetchAll, state.query]);

  const handleGoToPage = useCallback(
    (page: number) => {
      actions.setQuery({ page });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const onSubmit = useCallback(() => {
    if (state.query.page === 1) {
      actions.fetchAll();
    } else {
      actions.setQuery({
        page: 1,
      });
    }
  }, [actions.fetchAll, actions.setQuery]);

  let columns: Column<any>[] = [
    {
      Header: "구분",
      accessor: (row) => row.renderTypeWithPriceType(),
    },
    {
      Header: "예약번호",
      accessor: (row) => (
        <Link to={`/deliveries/${row.id}`}>{row.reservationNumber}</Link>
      ),
      id: "reservationNumber",
    },
    {
      Header: "신청자 (이메일, 연락처)",
      accessor: (row) => (
        <UserLink to={`/users/${row.userId}`}>{row.userContactEmail}</UserLink>
      ),
    },
    {
      Header: "상태",
      accessor: (row) => row.renderStatus(),
    },
    {
      Header: "운송장 번호",
      accessor: (row) => row.trackingNumber,
    },
    {
      Header: "신청일",
      accessor: (row) => row.renderCreatedAt(),
    },
    {
      Header: "보내는 분 (이름, 연락처)",
      accessor: (row) => row.senderNameContact,
    },
    {
      Header: "받는 분 (이름, 연락처)",
      accessor: (row) => row.receiverNameContact,
    },
  ];

  return (
    <Container>
      <Row>
        <Title>택배 신청</Title>
      </Row>
      <SearchRow>
        <SearchForm onSubmit={handleSubmit(onSubmit)}>
          <FirstRow>
            <SearchCol md={4}>
              <Form.Label column sm={5}>
                신청자 이메일
              </Form.Label>
              <SearchCol sm={7}>
                <Form.Group>
                  <Form.Control
                    name="userEmail"
                    onChange={handleChange}
                    value={state.query.userEmail}
                  />
                </Form.Group>
              </SearchCol>
            </SearchCol>
            <SearchCol md={4}>
              <Form.Label column sm={5}>
                보내는 분 연락처
              </Form.Label>
              <SearchCol sm={7}>
                <Form.Group>
                  <Form.Control
                    name="senderMobile"
                    onChange={handleChange}
                    value={state.query.senderMobile}
                  />
                </Form.Group>
              </SearchCol>
            </SearchCol>
            <SearchCol md={4}>
              <Form.Label column sm={5}>
                받는 분 연락처
              </Form.Label>
              <SearchCol sm={7}>
                <Form.Group>
                  <Form.Control
                    name="receiverMobile"
                    onChange={handleChange}
                    value={state.query.receiverMobile}
                  />
                </Form.Group>
              </SearchCol>
            </SearchCol>
          </FirstRow>
          <SearchCol md={4}>
            <Form.Label column sm={5}>
              구분
            </Form.Label>
            <SearchCol sm={7}>
              <Form.Group>
                <Form.Control
                  as="select"
                  custom
                  name="priceType"
                  onChange={handleChange}
                  value={state.query.priceType}
                >
                  <option value="">선택</option>
                  <option value={PRICE_TYPE_NORMAL}>편의접택배</option>
                  <option value={PRICE_TYPE_HALF}>반값택배</option>
                </Form.Control>
              </Form.Group>
            </SearchCol>
          </SearchCol>
          <SearchCol md={4}>
            <Form.Label column sm={5}>
              상태
            </Form.Label>
            <SearchCol sm={7}>
              <Form.Group>
                <Form.Control
                  as="select"
                  custom
                  name="status"
                  onChange={handleChange}
                  value={state.query.status}
                >
                  <option value="">선택</option>
                  <option value="PAYMENT_WAITING">접수전</option>
                  <option value="READY">배송준비</option>
                  <option value="PICKUP">배송중</option>
                  <option value="DELIVERED">배송완료</option>
                </Form.Control>
              </Form.Group>
            </SearchCol>
          </SearchCol>
          <SearchCol md={{ span: 2 }}>
            <SearchButton type="submit">검색하기</SearchButton>
            <ResetButton variant="danger" onClick={handleClear}>
              초기화
            </ResetButton>
          </SearchCol>
        </SearchForm>
        <RefreshButton variant="info" onClick={handleClickRefresh}>
          목록 새로고침
        </RefreshButton>
      </SearchRow>
      <Row>
        <Col>
          <DeliveriesTable
            columns={columns}
            currentPage={state.query.page}
            data={state.deliveries}
            gotoPage={handleGoToPage}
            nextPage={handleNextPage}
            pageSize={state.query.pageSize}
            pageCount={state.pageCount}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Deliveries;
