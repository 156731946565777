/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Column } from "react-table";
import styled from "@emotion/styled";

import TableComponent from "@/components/Table";
import { useQnasContext } from "@/store";

const ButtonsRow = styled(Row)`
  margin: 10px 0;
  flex-direction: row-reverse;
`;

const QnasTable = styled(TableComponent)``;

const Title = styled.h1`
  padding-left: 15px;
`;

const Qnas = () => {
  const { state, ...actions } = useQnasContext();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  useEffect(() => {
    actions.fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const handleClickRefresh = useCallback(() => {
    actions.clear();
    actions.setQuery({
      page: state.query.page,
      pageSize: state.query.pageSize,
    });
    actions.fetchAll();
  }, [actions.clear, actions.fetchAll, state.query]);

  const handleGoToPage = useCallback(
    (page: number) => {
      actions.setQuery({ page });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  let columns: Column<any>[] = [
    {
      Header: "ID",
      accessor: "id",
      id: "id",
    },
    {
      Header: "제목",
      accessor: (row) => <Link to={`/qnas/${row.id}`}>{row.title}</Link>,
      id: "title",
    },
    {
      Header: "이름(이메일)",
      accessor: (row) => row.userNameEmail,
    },
    {
      Header: "연락처",
      accessor: (row) => row.userContact,
    },
    {
      Header: "답변여부",
      accessor: (row) => row.answer ? "완료" : "",
    },
    {
      Header: "작성일시",
      accessor: (row) => row.renderCreatedAt(),
    },
  ];

  return (
    <Container>
      <Row>
        <Title>문의/제안</Title>
      </Row>
      <ButtonsRow>
        <Button variant="info" onClick={handleClickRefresh}>
          목록 새로고침
        </Button>
      </ButtonsRow>
      <Row>
        <Col>
          <QnasTable
            columns={columns}
            currentPage={state.query.page}
            data={state.qnas}
            gotoPage={handleGoToPage}
            nextPage={handleNextPage}
            pageSize={state.query.pageSize}
            pageCount={state.pageCount}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Qnas;
