import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from "react";
import ky from "ky";

import { Qna } from "@/Models/Qna";

export type QnasResponse = {
  answer?: string;
  content: string;
  createdAt: string;
  id: number;
  title: string;
  updatedAt: string;
  user: {
    contact?: string;
    email?: string;
    id: number;
    name?: string;
  };
};

export type QnaDetail = {
  answer: string;
  content: string;
  createdAt: Date | undefined;
  id: null | number;
  title: string;
  user: any;
};

export type Answer = {
  answer: string;
};

enum ACTION_TYPES {
  CLEAR = "CLEAR",
  FETCH_ALL = "FETCH_ALL",
  FETCH_BY_ID = "FETCH_BY_ID",
  SET_QUERY = "SET_QUERY",
  UPDATE = "UPDATE",
}

type Action =
  | { type: ACTION_TYPES.CLEAR }
  | { type: ACTION_TYPES.FETCH_ALL; qnas: QnasResponse[]; pageCount: number }
  | { type: ACTION_TYPES.FETCH_BY_ID; qnaDetail: QnaDetail }
  | { type: ACTION_TYPES.SET_QUERY; query: Query }
  | { type: ACTION_TYPES.UPDATE };

type Query = {
  page: number;
  pageSize: number;
};

interface State {
  qnas: QnasResponse[];
  qnaDetail: QnaDetail;
  pageCount: number;
  query: Query;
}

const INITIAL_STATE: State = {
  qnas: [],
  qnaDetail: {
    answer: "",
    content: "",
    createdAt: undefined,
    id: null,
    title: "",
    user: {},
  },
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
  },
};

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.CLEAR:
      return INITIAL_STATE;
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...prevState,
        qnas: action.qnas,
        pageCount: action.pageCount,
      };
    case ACTION_TYPES.FETCH_BY_ID:
      return {
        ...prevState,
        qnaDetail: action.qnaDetail,
      };
    case ACTION_TYPES.SET_QUERY:
      return {
        ...prevState,
        query: {
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext<{
  state: typeof INITIAL_STATE;
  dispatch: (action: Action) => void;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useQnasContext = () => {
  const { state, dispatch } = useContext(Context);

  const clear = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR,
    });
  }, [dispatch]);

  const fetchAll = useCallback(async () => {
    try {
      const response = await ky.get(`${process.env.REACT_APP_API_HOST}/qnas`, {
        searchParams: {
          page: state.query.page,
          pageSize: state.query.pageSize,
        },
        credentials: "include",
      });
      const { qnas, pageCount } = await response.json();

      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        qnas: qnas.map((q: QnasResponse) => new Qna(q)),
        pageCount,
      });
    } catch (e) {
      throw await e.response.json();
    }
  }, [dispatch, state.query]);

  const fetchById = useCallback(
    async (id) => {
      try {
        const response = await ky.get(
          `${process.env.REACT_APP_API_HOST}/qnas/${id}`,
          {
            credentials: "include",
          }
        );
        const qnaDetail = await response.json();

        dispatch({
          type: ACTION_TYPES.FETCH_BY_ID,
          qnaDetail,
        });
      } catch (e) {
        throw await e.response.json();
      }
    },
    [dispatch]
  );

  const setQuery = useCallback(
    (query: { page?: number; pageSize?: number }) => {
      try {
        dispatch({
          type: ACTION_TYPES.SET_QUERY,
          query: {
            ...state.query,
            ...query,
          },
        });
      } catch (e) {
        throw e;
      }
    },
    [dispatch, state.query]
  );

  const update = useCallback((id, { answer }: Answer) => {
    return ky.put(`${process.env.REACT_APP_API_HOST}/qnas/${id}`, {
      json: { answer },
      credentials: "include",
    });
  }, []);

  return {
    state,
    clear,
    fetchAll,
    fetchById,
    setQuery,
    update,
  };
};
