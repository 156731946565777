import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

const PaginationRow = styled(Row)`
  margin-bottom: 3rem;
`;

const Pagination: React.FC<{
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  gotoPage: (page: number) => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
}> = ({
  canNextPage,
  canPreviousPage,
  nextPage,
  gotoPage,
  pageCount,
  pageIndex,
  pageSize,
  previousPage,
  setPageSize,
}) => {
  const handleChangeSelectPageCount = useCallback(
    (e: any) => {
      setPageSize(Number(e.target.value));
    },
    [setPageSize]
  );

  const handleClickFirstPage = useCallback(() => {
    gotoPage(1);
  }, [gotoPage]);

  const handleClickLastPage = useCallback(() => {
    gotoPage(pageCount);
  }, [gotoPage, pageCount]);

  const handleClickNextPage = useCallback(() => {
    nextPage();
  }, [nextPage]);

  const handleClickPreviousPage = useCallback(() => {
    previousPage();
  }, [previousPage]);

  return (
    <PaginationRow>
      <Col>
        <ButtonGroup>
          <Button onClick={handleClickFirstPage} disabled={!canPreviousPage}>
            {"<<"}
          </Button>{" "}
          <Button onClick={handleClickPreviousPage} disabled={!canPreviousPage}>
            {"<"}
          </Button>{" "}
        </ButtonGroup>
      </Col>
      <Col>
        <span>
          페이지{" "}
          <strong>
            {pageIndex + 1}/{pageCount}
          </strong>
        </span>
      </Col>
      <Col>
        <ButtonGroup>
          <Button onClick={handleClickNextPage} disabled={!canNextPage}>
            {">"}
          </Button>{" "}
          <Button onClick={handleClickLastPage} disabled={!canNextPage}>
            {">>"}
          </Button>{" "}
        </ButtonGroup>
      </Col>
      <Col>
        <Form.Control
          as="select"
          value={pageSize}
          onChange={handleChangeSelectPageCount}
        >
          {[5, 10, 20, 25, 50, 100, 500, 1000].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}개씩 보기
            </option>
          ))}
        </Form.Control>
      </Col>
    </PaginationRow>
  );
};

export default Pagination;
