import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from "react";
import ky from "ky";

import { Delivery } from "@/Models/Delivery";

export type Deliveries = {
  createdAt: string;
  id: number;
  receiverAddress?: {
    receiverName?: string;
    receiverMobile?: string;
    reservationNumber?: string;
  };
  status: string;
  senderName: string;
  senderMobile: string;
  trackingNumber?: string;
  user: {
    email?: string;
    contact?: string;
  };
};

export type DeliveryDetail = {
  id: null | number;
  reservationNumber: string;
  status: string;
  type: string,
  priceType: string,
  user: {
    name: string;
    contact: string;
    email: string;
  };
  senderName: string;
  senderMobile: string;
  senderPostCode: string;
  senderAddress: string;
  senderAddressDetail: string;
  typeWithPriceType: string;
  receiverAddress: {
    receiverName: string;
    receiverMobile: string;
    postCode: string;
    address: string;
    addressDetail: string;
  };
  category: string;
  price: number;
  goodsName: string;
  memo: string;
  prepaid: null | boolean;
  createdAt: undefined | Date;
  paidAt: undefined | Date;
  pickedUpAt: undefined | Date;
  deliveredAt: undefined | Date;
  trackingNumber: string;
  userId: null | number;
  etc: string;
  etcObj: {
    receiverStoreAddress: string,
    receiverStoreAddressDetail: string,
    receiverStoreName: string,
  };
  receiptedAt: undefined | Date;
  gatheredAt: undefined | Date;
  cancelledAt: undefined | Date;
  returnedAt: undefined | Date;
  arrivedAt: undefined | Date;
};

enum ACTION_TYPES {
  CLEAR = "CLEAR",
  FETCH_ALL = "FETCH_ALL",
  FETCH_BY_ID = "FETCH_BY_ID",
  RESET_QUERY = "RESET_QUERY",
  SET_QUERY = "SET_QUERY",
}

type Action =
  | { type: ACTION_TYPES.CLEAR }
  | {
      type: ACTION_TYPES.FETCH_ALL;
      deliveries: Deliveries[];
      pageCount: number;
    }
  | { type: ACTION_TYPES.FETCH_BY_ID; deliveryDetail: DeliveryDetail }
  | { type: ACTION_TYPES.RESET_QUERY }
  | { type: ACTION_TYPES.SET_QUERY; query: Query };

type Query = {
  page: number;
  pageSize: number;
  priceType: string;
  receiverMobile: string;
  senderMobile: string;
  status: string;
  userEmail: string;
};

interface State {
  deliveries: Deliveries[];
  deliveryDetail: DeliveryDetail;
  pageCount: number;
  query: Query;
}

const INITIAL_STATE: State = {
  deliveries: [],
  deliveryDetail: {
    id: null,
    type: "",
    priceType: "",
    reservationNumber: "",
    status: "",
    user: {
      name: "",
      contact: "",
      email: "",
    },
    senderName: "",
    senderMobile: "",
    senderPostCode: "",
    senderAddress: "",
    senderAddressDetail: "",
    typeWithPriceType: "",
    receiverAddress: {
      receiverName: "",
      receiverMobile: "",
      postCode: "",
      address: "",
      addressDetail: "",
    },
    category: "",
    price: 0,
    goodsName: "",
    memo: "",
    prepaid: null,
    createdAt: undefined,
    paidAt: undefined,
    pickedUpAt: undefined,
    deliveredAt: undefined,
    trackingNumber: "",
    userId: null,
    etc: "",
    etcObj: {
      receiverStoreAddress: "",
      receiverStoreAddressDetail: "",
      receiverStoreName: "",
    },
    receiptedAt: undefined,
    gatheredAt: undefined,
    cancelledAt: undefined,
    returnedAt: undefined,
    arrivedAt: undefined,
  },
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
    priceType: "",
    receiverMobile: "",
    senderMobile: "",
    status: "",
    userEmail: "",
  },
};

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.CLEAR:
      return INITIAL_STATE;
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...prevState,
        deliveries: action.deliveries,
        pageCount: action.pageCount,
      };
    case ACTION_TYPES.FETCH_BY_ID:
      return {
        ...prevState,
        deliveryDetail: action.deliveryDetail,
      };
    case ACTION_TYPES.RESET_QUERY:
      return {
        ...prevState,
        query: INITIAL_STATE.query,
      };
    case ACTION_TYPES.SET_QUERY:
      return {
        ...prevState,
        query: {
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext<{
  state: typeof INITIAL_STATE;
  dispatch: (action: Action) => void;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesContext = () => {
  const { state, dispatch } = useContext(Context);

  const clear = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR,
    });
  }, [dispatch]);

  const fetchAll = useCallback(async () => {
    try {
      const response = await ky.get(
        `${process.env.REACT_APP_API_HOST}/deliveries`,
        {
          searchParams: {
            page: state.query.page,
            pageSize: state.query.pageSize,
            priceType: state.query.priceType,
            receiverMobile: state.query.receiverMobile,
            senderMobile: state.query.senderMobile,
            status: state.query.status,
            userEmail: state.query.userEmail,
          },
          credentials: "include",
        }
      );
      const { deliveries, pageCount } = await response.json();

      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        deliveries: deliveries.map((d: Deliveries) => new Delivery(d)),
        pageCount,
      });
    } catch (e) {
      throw await e.response.json();
    }
  }, [dispatch, state.query]);

  const fetchById = useCallback(
    async (id) => {
      try {
        const response = await ky.get(
          `${process.env.REACT_APP_API_HOST}/deliveries/${id}`,
          {
            credentials: "include",
          }
        );
        const deliveryDetail = await response.json();
        
        dispatch({
          type: ACTION_TYPES.FETCH_BY_ID,
          deliveryDetail: new Delivery(deliveryDetail),
        });
      } catch (e) {
        throw await e.response.json();
      }
    },
    [dispatch]
  );

  const resetQuery = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.RESET_QUERY,
    });
  }, [dispatch]);

  const setQuery = useCallback(
    (query: {
      page?: number;
      pageSize?: number;
      priceType?: string;
      receiverMobile?: string;
      senderMobile?: string;
      status?: string;
      userEmail?: string;
    }) => {
      try {
        dispatch({
          type: ACTION_TYPES.SET_QUERY,
          query: {
            ...state.query,
            ...query,
          },
        });
      } catch (e) {
        throw e;
      }
    },
    [dispatch, state.query]
  );

  return {
    state,
    clear,
    fetchAll,
    fetchById,
    resetQuery,
    setQuery,
  };
};
