import React, { useCallback } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { ExcelUploadModal } from "@/Modals";
import { useModalStore } from "@/store";

const ButtonsRow = styled(Row)`
  margin: 10px 0;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

const Strike = () => {
  const { closeModal, openModal } = useModalStore();

  const handleSubmit = useCallback(
    async (arr) => {
      try {
        console.log(arr);

        closeModal();
      } catch (e) {
        alert(e.message);
      }
    },
    [closeModal]
  );

  const handleClickFileUpload = useCallback(() => {
    openModal(
      <ExcelUploadModal
        key="popup-excel-upload-modal"
        onSubmit={handleSubmit}
        title="팝업 관리 파일 업로드"
      />
    );
  }, [openModal, handleSubmit]);

  return (
    <Container>
      <Row>
        <Title>파업 지역 관리</Title>
      </Row>
      <ButtonsRow>
        <Button variant="info" onClick={handleClickFileUpload}>
          파일 업로드
        </Button>
      </ButtonsRow>
    </Container>
  );
};

export default Strike;
