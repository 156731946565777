/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Column } from "react-table";
import styled from "@emotion/styled";

import TableComponent from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useNoticesContext } from "@/store";

const ButtonsRow = styled(Row)`
  margin: 10px 0;
  flex-direction: row-reverse;
`;

const CreateButton = styled(Button)`
  margin-right: 10px;
`;

const CreateLink = styled(Link)`
  color: #ffffff;

  &: hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

const Title = styled.h1`
  padding-left: 15px;
`;

const NoticesTable = styled(TableComponent)``;

const Notices: React.FC = () => {
  const { state, ...actions } = useNoticesContext();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  useEffect(() => {
    actions.fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const handleGoToPage = useCallback(
    (page: number) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize: number) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  let columns: Column<any>[] = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "제목",
      accessor: (row) => <Link to={`/notices/${row.id}`}>{row.title}</Link>,
      id: "title",
    },
    {
      Header: "등록일시",
      accessor: (row) =>
        formatDate(getDateByTimeZone(row.createdAt), "yyyy/MM/dd HH:mm"),
    },
  ];

  return (
    <Container>
      <Row>
        <Title>공지사항</Title>
      </Row>
      <ButtonsRow>
        <CreateButton>
          <CreateLink to="/notices/create-notice">생성하기</CreateLink>
        </CreateButton>
      </ButtonsRow>
      <Row>
        <Col>
          <NoticesTable
            columns={columns}
            currentPage={state.query.page}
            data={state.notices}
            gotoPage={handleGoToPage}
            nextPage={handleNextPage}
            pageSize={state.query.pageSize}
            pageCount={state.pageCount}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Notices;
