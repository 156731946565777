import React from "react";
import Col from "react-bootstrap/Col";
import styled from "@emotion/styled";

import Dropzone from "@/components/Dropzone";
import { Modal, ModalHeader, ModalBody } from "@/components/Modal";

const Title = styled.h3``;

export type DropzoneProps = {
  accept?: string | string[];
  onUpload?: any; //TODO
};

interface Props {
  dropzoneProps?: DropzoneProps;
  title?: string;
}

const ImageUploadModal: React.FC<Props> = ({ dropzoneProps = {}, title }) => {
  return (
    <Modal>
      <ModalHeader>
        <Title>{title}</Title>
      </ModalHeader>
      <ModalBody>
        <Col>
          <Dropzone {...dropzoneProps} />
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default ImageUploadModal;
