import { formatDate, getDateByTimeZone } from "@/lib/date";

export class Qna {
  answer = null;
  contact = null;
  createdAt = undefined;
  email = null;
  id = "";
  name = null;
  title = "";

  constructor(qna: any) {
    this.answer = qna.answer;
    this.contact = qna.user && qna.user.contact;
    this.createdAt = qna.createdAt;
    this.email = qna.user && qna.user.email;
    this.id = qna.id;
    this.name = qna.user && qna.user.name;
    this.title = qna.title;
  }

  get userContact() {
    return this.contact;
  }

  get userNameEmail() {
    return this.name && this.email
      ? `${this.name} (${this.email})`
      : this.email;
  }

  renderCreatedAt(dateFormat = "yyyy/MM/dd HH:mm") {
    return (
      this.createdAt &&
      formatDate(getDateByTimeZone(this.createdAt), dateFormat)
    );
  }
}
