import React, {
  createContext,
  ReactNode,
  Reducer,
  useContext,
  useReducer,
} from "react";
import { useCookies } from "react-cookie";
import ky from "ky";

export type User = {
  username: string;
  password: string;
};

enum ACTION_TYPES {
  AUTH_SIGN_IN = "AUTH_SIGN_IN",
  AUTH_SIGN_OUT = "AUTH_SIGN_OUT",
}

type Action =
  | { type: ACTION_TYPES.AUTH_SIGN_IN; user: User }
  | { type: ACTION_TYPES.AUTH_SIGN_OUT };

interface State {
  user: User | null;
}

const INITIAL_STATE: State = {
  user: null,
};

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.AUTH_SIGN_IN:
      return {
        ...prevState,
        user: action.user,
      };
    case ACTION_TYPES.AUTH_SIGN_OUT:
      return {
        ...prevState,
        user: null,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext<{
  state: typeof INITIAL_STATE;
  dispatch: (action: Action) => void;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAuthStore = () => {
  const [cookies, setCookie, removeCookies] = useCookies(["token", "user"]);
  const { state, dispatch } = useContext(Context);

  const checkAlreadySignIn = () => {
    return !!cookies.user;
  };

  const signIn = async ({ username, password }: User) => {
    try {
      const response = await ky.post(
        `${process.env.REACT_APP_API_HOST}/auth/signin`,
        {
          json: { username, password },
          credentials: "include",
        }
      );
      const { token, user } = await response.json();
      setCookie("token", token, {
        httpOnly: true,
        secure: true,
      });
      setCookie("user", JSON.stringify(user), {
        httpOnly: true,
        secure: true,
      });

      dispatch({ type: ACTION_TYPES.AUTH_SIGN_IN, user });
    } catch (e) {
      throw await e.response.json();
    }
  };

  const signInExistingUser = () => {
    dispatch({ type: ACTION_TYPES.AUTH_SIGN_IN, user: cookies.user });
  };

  const signOut = () => {
    removeCookies("token");
    removeCookies("user");
    dispatch({ type: ACTION_TYPES.AUTH_SIGN_OUT });
  };

  return {
    state,
    checkAlreadySignIn,
    signIn,
    signInExistingUser,
    signOut,
  };
};
