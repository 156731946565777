import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import OriginTable from "react-bootstrap/Table";
import styled from "@emotion/styled";

import { 
  DELIVERY_STATUSES,
  TYPE_CUGS,
  TYPE_WITH_PRICETYPE_CUGS,
  PRICE_TYPE_HALF,
} from "@/Models/Delivery";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useDeliveriesContext } from "@/store";

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const SubTitle = styled.h3`
  font-size: 1.2em;
  margin: 10px 0;
  display: block;
  font-weight: bold;
`;

const Table = styled(OriginTable)`
  margin-bottom: 30px;
`;

const Th = styled.th`
  width: 350px;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

export default () => {
  const { state, ...actions } = useDeliveriesContext();
  let { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    actions.fetchById(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCancelledAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.cancelledAt &&
      formatDate(getDateByTimeZone(state.deliveryDetail.cancelledAt), dateFormat)
    );
  };

  const renderCreatedAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.createdAt &&
      formatDate(getDateByTimeZone(state.deliveryDetail.createdAt), dateFormat)
    );
  };

  const renderDeliveredAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.deliveredAt &&
      formatDate(
        getDateByTimeZone(state.deliveryDetail.deliveredAt),
        dateFormat
      )
    );
  };

  const renderGatheredAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.gatheredAt &&
      formatDate(
        getDateByTimeZone(state.deliveryDetail.gatheredAt),
        dateFormat
      )
    );
  };

  const renderPaidAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.paidAt &&
      formatDate(getDateByTimeZone(state.deliveryDetail.paidAt), dateFormat)
    );
  };

  const renderPrepaid = () => {
    return state.deliveryDetail.prepaid ? "선불" : "착불";
  };

  const renderPickedUpAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.pickedUpAt &&
      formatDate(getDateByTimeZone(state.deliveryDetail.pickedUpAt), dateFormat)
    );
  };

  const renderReceiptedAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.receiptedAt &&
      formatDate(getDateByTimeZone(state.deliveryDetail.receiptedAt), dateFormat)
    );
  };

  const renderReturnedAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.returnedAt &&
      formatDate(getDateByTimeZone(state.deliveryDetail.returnedAt), dateFormat)
    );
  };

  const renderArrivedAt = (dateFormat = "yyyy/MM/dd HH:mm") => {
    return (
      state.deliveryDetail.arrivedAt &&
      formatDate(
        getDateByTimeZone(state.deliveryDetail.arrivedAt),
        dateFormat
      )
    );
  };

  const renderStatus = () => {
    switch (state.deliveryDetail.status) {
      case DELIVERY_STATUSES.PAYMENT_WAITING:
      case DELIVERY_STATUSES.RESERVED:
      case DELIVERY_STATUSES.PRINTED:
        return '접수전';
      case DELIVERY_STATUSES.CONFIRMED:
      case DELIVERY_STATUSES.READY:
      case DELIVERY_STATUSES.RECEIPTED:
        return '배송준비';
      case DELIVERY_STATUSES.PICKUP:
      case DELIVERY_STATUSES.ARRIVED:
      case DELIVERY_STATUSES.GATHERED:
        return '배송중';
      case DELIVERY_STATUSES.DELIVERED:
        return '배송완료';
      case DELIVERY_STATUSES.CANCELLED:
        return '예약취소';
      case DELIVERY_STATUSES.RETURNED:
        return '반송완료';
      default:
        return '';
    }
  }

  const renderNameWithEmail = () => {
    if (state.deliveryDetail.user && state.deliveryDetail.user.name !== "") {
      return `${state.deliveryDetail.user.name} (${state.deliveryDetail.user.email})`;
    } else if (
      state.deliveryDetail.user &&
      state.deliveryDetail.user.name === ""
    ) {
      return state.deliveryDetail.user.email;
    }
  };

  const renderTypeWithPriceType = () => {
    if (state.deliveryDetail.type === TYPE_CUGS) {
      if (state.deliveryDetail.priceType === PRICE_TYPE_HALF) {
        return '반값택배';
      } else {
        return '편의점택배';
      }
    } else {
      return '편의점택배';
    }
  }

  return (
    <Container>
      <Row>
        <Title>택배 신청</Title>
      </Row>
      <Card>
        <Card.Header as="h5">택배 상세 조회</Card.Header>
        <Card.Body>
          <Table bordered>
            <tbody>
              <tr>
                <Th>구분</Th>
                <td>{renderTypeWithPriceType()}</td>
              </tr>
              <tr>
                <Th>예약번호(배송상태)</Th>
                <td>{`${
                  state.deliveryDetail.reservationNumber
                } (${renderStatus()})`}</td>
              </tr>
            </tbody>
          </Table>

          <SubTitle>신청자</SubTitle>
          <Table bordered>
            <tbody>
              <tr>
                <Th>이름(이메일)</Th>
                <td>
                  <Link to={`/users/${state.deliveryDetail.userId}`}>
                    {renderNameWithEmail()}
                  </Link>
                </td>
              </tr>
              <tr>
                <Th>연락처</Th>
                <td>
                  <Link to={`/users/${state.deliveryDetail.userId}`}>
                    {state.deliveryDetail.user.contact}
                  </Link>
                </td>
              </tr>
            </tbody>
          </Table>

          <SubTitle>보내는 분 정보</SubTitle>
          <Table bordered>
            <tbody>
              <tr>
                <Th>이름</Th>
                <td>{state.deliveryDetail.senderName}</td>
              </tr>
              <tr>
                <Th>연락처</Th>
                <td>{state.deliveryDetail.senderMobile}</td>
              </tr>
              <tr>
                <Th>우편번호</Th>
                <td>{state.deliveryDetail.senderPostCode}</td>
              </tr>
              <tr>
                <Th>주소</Th>
                <td>{state.deliveryDetail.senderAddress}</td>
              </tr>
              <tr>
                <Th>상세주소</Th>
                <td>{state.deliveryDetail.senderAddressDetail}</td>
              </tr>
            </tbody>
          </Table>

          <SubTitle>받는 분 정보</SubTitle>
          <Table bordered>
            {state.deliveryDetail.typeWithPriceType === TYPE_WITH_PRICETYPE_CUGS ? (
              <tbody>
                <tr>
                  <Th>이름</Th>
                  <td>{state.deliveryDetail.receiverAddress.receiverName}</td>
                </tr>
                <tr>
                  <Th>연락처</Th>
                  <td>{state.deliveryDetail.receiverAddress.receiverMobile}</td>
                </tr>
                <tr>
                  <Th>우편번호</Th>
                  <td>{state.deliveryDetail.receiverAddress.postCode}</td>
                </tr>
                <tr>
                  <Th>주소</Th>
                  <td>{state.deliveryDetail.receiverAddress.address}</td>
                </tr>
                <tr>
                  <Th>상세주소</Th>
                  <td>{state.deliveryDetail.receiverAddress.addressDetail}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <Th>이름</Th>
                  <td>{state.deliveryDetail.receiverAddress.receiverName}</td>
                </tr>
                <tr>
                  <Th>연락처</Th>
                  <td>{state.deliveryDetail.receiverAddress.receiverMobile}</td>
                </tr>
                <tr>
                  <Th>편의점명</Th>
                  <td>{state.deliveryDetail.etcObj.receiverStoreName}</td>
                </tr>
                <tr>
                  <Th>편의점 주소</Th>
                  <td>{state.deliveryDetail.etcObj.receiverStoreAddress}</td>
                </tr>
                <tr>
                  <Th>편의점 상세주소</Th>
                  <td>{state.deliveryDetail.etcObj.receiverStoreAddressDetail}</td>
                </tr>
              </tbody>
            )}
          </Table>

          <SubTitle>물품 정보</SubTitle>
          <Table bordered>
            <tbody>
              {state.deliveryDetail.typeWithPriceType === TYPE_WITH_PRICETYPE_CUGS && (
                <tr>
                  <Th>품목</Th>
                  <td>{state.deliveryDetail.category}</td>
                </tr>
              )}
              <tr>
                <Th>물품가액</Th>
                <td>
                  {new Intl.NumberFormat("ko-KR").format(
                    state.deliveryDetail.price
                  )}
                  원
                </td>
              </tr>
              <tr>
                <Th>물품명</Th>
                <td>{state.deliveryDetail.goodsName}</td>
              </tr>
              <tr>
                <Th>배송시요청</Th>
                <td>{state.deliveryDetail.memo}</td>
              </tr>
              {state.deliveryDetail.typeWithPriceType === TYPE_WITH_PRICETYPE_CUGS && (
                <tr>
                  <Th>운임지불</Th>
                  <td>{renderPrepaid()}</td>
                </tr>
              )}
            </tbody>
          </Table>

          <SubTitle>배송 정보</SubTitle>
          <Table bordered>
            {state.deliveryDetail.typeWithPriceType === TYPE_WITH_PRICETYPE_CUGS ? (
              <tbody>
                <tr>
                  <Th>신청일</Th>
                  <td>{renderCreatedAt()}</td>
                </tr>
                <tr>
                  <Th>결제일</Th>
                  <td>{renderPaidAt()}</td>
                </tr>
                <tr>
                  <Th>수거일</Th>
                  <td>{renderPickedUpAt()}</td>
                </tr>
                <tr>
                  <Th>배송완료일</Th>
                  <td>{renderDeliveredAt()}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <Th>신청일</Th>
                  <td>{renderCreatedAt()}</td>
                </tr>
                <tr>
                  <Th>접수일(RECEIPTED)</Th>
                  <td>{renderReceiptedAt()}</td>
                </tr>
                <tr>
                  <Th>배송매니저 수거일(GATHERED)</Th>
                  <td>{renderGatheredAt()}</td>
                </tr>
                <tr>
                  <Th>배송완료일(DELIVERED)</Th>
                  <td>{renderDeliveredAt() || renderArrivedAt()}</td>
                </tr>
                <tr>
                  <Th>취소일(CANCELLED)</Th>
                  <td>{renderCancelledAt()}</td>
                </tr>
                <tr>
                  <Th>반송일(RETURNED)</Th>
                  <td>{renderReturnedAt()}</td>
                </tr>
              </tbody>
            )}
          </Table>

          <Table bordered>
            <tbody>
              <tr>
                <Th>운송장번호</Th>
                <td>{state.deliveryDetail.trackingNumber}</td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Link to="/deliveries">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
