import React from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { useAuthStore } from "@/store";
import { INDEX_PRIVATE_ROUTE_PATHNAME } from "@/routes";

type FormData = {
  username: string;
  password: string;
};

const AuthRow = styled(Row)`
  width: 1000px;
  margin: 0 auto;
`;

export default () => {
  const { register, handleSubmit } = useForm<FormData>();
  const { ...actions } = useAuthStore();

  const history = useHistory();

  const onSubmit = handleSubmit(({ username, password }) => {
    actions
      .signIn({
        username,
        password,
      })
      .then((res) => {
        history.push(INDEX_PRIVATE_ROUTE_PATHNAME);
      })
      .catch((error) => {
        window.alert(error.message);
        history.push("/");
      });
  });

  return (
    <AuthRow>
      <Col>
        <Form onSubmit={onSubmit}>
          <Form.Group controlId="username">
            <Form.Label>사용자 이름</Form.Label>
            <Form.Control
              name="username"
              placeholder="사용자 이름"
              ref={register}
            />
          </Form.Group>

          <Form.Group controlId="password">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              name="password"
              type="password"
              placeholder="비밀번호"
              ref={register}
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            로그인하기
          </Button>
        </Form>
      </Col>
    </AuthRow>
  );
};
