import React, {
  createContext,
  ReactNode,
  Reducer,
  useCallback,
  useContext,
  useReducer,
} from "react";
import ky from "ky";

export type UsersResponse = {
  contact?: string;
  createdAt: string;
  defaultAddress?: string | null;
  defaultAddressDetail?: string | null;
  defaultPostCode?: number | null;
  deletedAt: null | Date;
  email: string;
  etc?: string | null;
  id: number;
  loggedAt: null | Date;
  name?: string | null;
};

export type userDetail = {
  contact: string;
  createdAt: undefined | Date;
  defaultAddress?: string;
  defaultAddressDetail?: string;
  defaultPostCode?: string;
  deleted: boolean;
  deletedAt: null | Date;
  email: string;
  etc: string;
  id: null | number;
  loggedAt: null | Date;
  name: string;
};

export type LatestDeliveries = {
  createdAt: undefined | Date;
  id: number;
  receiverAddress: {
    receiverName: string;
    receiverMobile: string;
  };
  reservationNumber: string;
  status: string;
};

enum ACTION_TYPES {
  CLEAR = "CLEAR",
  FETCH_ALL = "FETCH_ALL",
  FETCH_BY_ID = "FETCH_BY_ID",
  FETCH_LATEST_DELIVERIES = "FETCH_LATEST_DELIVERIES",
  RESET_QUERY = "RESET_QUERY",
  SET_QUERY = "SET_QUERY",
}

type Action =
  | { type: ACTION_TYPES.CLEAR }
  | { type: ACTION_TYPES.FETCH_ALL; users: UsersResponse[]; pageCount: number }
  | { type: ACTION_TYPES.FETCH_BY_ID; userDetail: userDetail }
  | {
      type: ACTION_TYPES.FETCH_LATEST_DELIVERIES;
      latestDeliveries: LatestDeliveries[];
    }
  | { type: ACTION_TYPES.RESET_QUERY }
  | { type: ACTION_TYPES.SET_QUERY; query: Query };

type Query = {
  contact: string;
  email: string;
  page: number;
  pageSize: number;
};

interface State {
  latestDeliveries: LatestDeliveries[];
  users: UsersResponse[];
  userDetail: userDetail;
  pageCount: number;
  query: Query;
}

const INITIAL_STATE: State = {
  latestDeliveries: [],
  users: [],
  userDetail: {
    contact: "",
    createdAt: undefined,
    defaultAddress: "",
    defaultAddressDetail: "",
    defaultPostCode: "",
    deleted: false,
    deletedAt: null,
    email: "",
    etc: "",
    loggedAt: null,
    name: "",
    id: null,
  },
  pageCount: 1,
  query: {
    contact: "",
    email: "",
    page: 1,
    pageSize: 10,
  },
};

const reducer: Reducer<State, Action> = (
  prevState: State,
  action: Action
): State => {
  switch (action.type) {
    case ACTION_TYPES.CLEAR:
      return INITIAL_STATE;
    case ACTION_TYPES.FETCH_ALL:
      return {
        ...prevState,
        users: action.users,
        pageCount: action.pageCount,
      };
    case ACTION_TYPES.FETCH_BY_ID:
      return {
        ...prevState,
        userDetail: action.userDetail,
      };
    case ACTION_TYPES.FETCH_LATEST_DELIVERIES:
      return {
        ...prevState,
        latestDeliveries: action.latestDeliveries,
      };
    case ACTION_TYPES.RESET_QUERY:
      return {
        ...prevState,
        query: INITIAL_STATE.query,
      };
    case ACTION_TYPES.SET_QUERY:
      return {
        ...prevState,
        query: {
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext<{
  state: typeof INITIAL_STATE;
  dispatch: (action: Action) => void;
}>({
  state: INITIAL_STATE,
  dispatch: () => {},
});

export const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useUsersContext = () => {
  const { state, dispatch } = useContext(Context);

  const clear = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.CLEAR,
    });
  }, [dispatch]);

  const fetchAll = useCallback(async () => {
    try {
      const response = await ky.get(`${process.env.REACT_APP_API_HOST}/users`, {
        searchParams: {
          contact: state.query.contact,
          email: state.query.email,
          page: state.query.page,
          pageSize: state.query.pageSize,
        },
        credentials: "include",
      });
      const { users, pageCount } = await response.json();

      dispatch({
        type: ACTION_TYPES.FETCH_ALL,
        users,
        pageCount,
      });
    } catch (e) {
      throw await e.response.json();
    }
  }, [dispatch, state.query]);

  const fetchById = useCallback(
    async (id) => {
      try {
        const response = await ky.get(
          `${process.env.REACT_APP_API_HOST}/users/${id}`,
          {
            credentials: "include",
          }
        );
        const userDetail = await response.json();

        dispatch({
          type: ACTION_TYPES.FETCH_BY_ID,
          userDetail,
        });
      } catch (e) {
        throw await e.response.json();
      }
    },
    [dispatch]
  );

  const fetchLatestDeliveries = useCallback(
    async (id) => {
      try {
        const response = await ky.get(
          `${process.env.REACT_APP_API_HOST}/users/${id}/latest-deliveries`,
          {
            credentials: "include",
          }
        );
        const latestDeliveries = await response.json();

        dispatch({
          type: ACTION_TYPES.FETCH_LATEST_DELIVERIES,
          latestDeliveries,
        });
      } catch (e) {
        throw await e.response.json();
      }
    },
    [dispatch],
  );

  const resetQuery = useCallback(() => {
    dispatch({
      type: ACTION_TYPES.RESET_QUERY,
    });
  }, [dispatch]);

  const setQuery = useCallback(
    (query: {
      contact?: string;
      email?: string;
      page?: number;
      pageSize?: number;
    }) => {
      try {
        dispatch({
          type: ACTION_TYPES.SET_QUERY,
          query: {
            ...state.query,
            ...query,
          },
        });
      } catch (e) {
        throw e;
      }
    },
    [dispatch, state.query]
  );

  return {
    state,
    clear,
    fetchAll,
    fetchById,
    fetchLatestDeliveries,
    resetQuery,
    setQuery,
  };
};
