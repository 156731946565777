import React from "react";
import { ThemeProvider } from "emotion-theming";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import {
  AuthProvider,
  DeliveriesProvider,
  ErrorProvider,
  LoadingProvider,
  ModalProvider,
  NoAuthPopupsProvider,
  NoticesProvider,
  PopupsProvider,
  QnasProvider,
  UsersProvider,
} from "./store";
import theme from "./theme";

import Router from "./Router";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <AuthProvider>
          <DeliveriesProvider>
            <ErrorProvider>
              <LoadingProvider>
                <ModalProvider>
                  <NoAuthPopupsProvider>
                    <NoticesProvider>
                      <PopupsProvider>
                        <QnasProvider>
                          <UsersProvider>
                            <BrowserRouter>
                              <Router />
                            </BrowserRouter>
                          </UsersProvider>
                        </QnasProvider>
                      </PopupsProvider>
                    </NoticesProvider>
                  </NoAuthPopupsProvider>
                </ModalProvider>
              </LoadingProvider>
            </ErrorProvider>
          </DeliveriesProvider>
        </AuthProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
