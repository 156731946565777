import CreateNotice from "@/pages/CreateNotice";
import CreateNoAuthPopup from "@/pages/CreateNoAuthPopup";
import CreatePopup from "@/pages/CreatePopup";
import Deliveries from "@/pages/Deliveries";
import DeliveryDetail from "@/pages/DeliveryDetail";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import NoAuthPopups from "@/pages/NoAuthPopups";
import NoAuthPopupDetail from "@/pages/NoAuthPopupDetail";
import Notices from "@/pages/Notices";
import NoticeDetail from "@/pages/NoticeDetail";
import Popups from "@/pages/Popups";
import PopupDetail from "@/pages/PopupDetail";
import Qnas from "@/pages/Qnas";
import QnaDetail from "@/pages/QnaDetail";
import Strike from "@/pages/Strike";
import Users from "@/pages/Users";
import UserDetail from "@/pages/UserDetail";

export const INDEX_PUBLIC_ROUTE_PATHNAME = "/";
export const INDEX_PRIVATE_ROUTE_PATHNAME = "/deliveries";

export default [
  {
    component: CreateNotice,
    exact: true,
    fluid: true,
    name: "CreateNotice",
    nav: true,
    path: "/notices/create-notice",
    private: true,
  },
  {
    component: CreateNoAuthPopup,
    exact: true,
    fluid: true,
    name: "CreateNoAuthPopup",
    nav: true,
    path: "/no-auth-popups/create-popup",
    private: true,
  },
  {
    component: CreatePopup,
    exact: true,
    fluid: true,
    name: "CreatePopup",
    nav: true,
    path: "/popups/create-popup",
    private: true,
  },
  {
    component: Deliveries,
    exact: true,
    fluid: true,
    name: "Deliveries",
    nav: true,
    path: "/deliveries",
    private: true,
  },
  {
    component: DeliveryDetail,
    exact: true,
    fluid: true,
    name: "DeliveryDetail",
    nav: true,
    path: "/deliveries/:slug",
    private: true,
  },
  {
    component: Home,
    exact: true,
    fluid: true,
    name: "Home",
    nav: true,
    path: "/home",
    private: true,
  },
  {
    component: Login,
    exact: true,
    name: "Login",
    path: "/",
  },
  {
    component: NoAuthPopups,
    exact: true,
    fluid: true,
    name: "NoAuthPopups",
    nav: true,
    path: "/no-auth-popups",
    private: true,
  },
  {
    component: NoAuthPopupDetail,
    exact: true,
    fluid: true,
    name: "NoAuthPopupDetail",
    nav: true,
    path: "/no-auth-popups/:slug",
    private: true,
  },
  {
    component: Notices,
    exact: true,
    fluid: true,
    name: "Notices",
    nav: true,
    path: "/notices",
    private: true,
  },
  {
    component: NoticeDetail,
    exact: true,
    fluid: true,
    name: "NoticeDetail",
    nav: true,
    path: "/notices/:slug",
    private: true,
  },
  {
    component: Popups,
    exact: true,
    fluid: true,
    name: "Popups",
    nav: true,
    path: "/popups",
    private: true,
  },
  {
    component: PopupDetail,
    exact: true,
    fluid: true,
    name: "PopupDetail",
    nav: true,
    path: "/popups/:slug",
    private: true,
  },
  {
    component: Strike,
    exact: true,
    fluid: true,
    name: "Strike",
    nav: true,
    path: "/strike",
    private: true,
  },
  {
    component: Qnas,
    exact: true,
    fluid: true,
    name: "Qnas",
    nav: true,
    path: "/qnas",
    private: true,
  },
  {
    component: QnaDetail,
    exact: true,
    fluid: true,
    name: "QnaDetail",
    nav: true,
    path: "/qnas/:slug",
    private: true,
  },
  {
    component: Users,
    exact: true,
    fluid: true,
    name: "Users",
    nav: true,
    path: "/users",
    private: true,
  },
  {
    component: UserDetail,
    exact: true,
    fluid: true,
    name: "UserDetail",
    nav: true,
    path: "/users/:slug",
    private: true,
  },
];
