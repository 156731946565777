import React, { useCallback } from "react";
import Col from "react-bootstrap/Col";
import styled from "@emotion/styled";
import XLSX from "xlsx";

import Dropzone from "@/components/Dropzone";
import { Modal, ModalHeader, ModalBody } from "@/components/Modal";

const Title = styled.h3``;

interface Props {
  title?: string;
  onSubmit?: any;
}

const ExcelUploadModal: React.FC<Props> = ({ title, onSubmit }) => {
  const handleUploadBulkFile = useCallback(
    async (binary) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        onSubmit(worksheetArr);
      } catch (e) {
        console.log(e)
      }
    },
    [onSubmit]
  );

  const dropzoneProps = {
    accept: ".xls, .xlsx",
    onUpload: handleUploadBulkFile,
  };

  return (
    <Modal>
      <ModalHeader>
        <Title>{title}</Title>
      </ModalHeader>
      <ModalBody>
        <Col>
          <Dropzone {...dropzoneProps} />
        </Col>
      </ModalBody>
    </Modal>
  );
};

export default ExcelUploadModal;
