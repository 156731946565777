import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Card from "react-bootstrap/Card";
import OriginContainer from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import styled from "@emotion/styled";

import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useUsersContext } from "@/store";

const Container = styled(OriginContainer)`
  margin-bottom: 100px;
`;

const SubTitle = styled.h3`
  font-size: 1.2em;
  margin: 30px 0 10px;
  display: block;
  font-weight: bold;
`;

const Title = styled.h1`
  padding-left: 15px;
`;

export default () => {
  const { state, ...actions } = useUsersContext();
  let { slug } = useParams<{ slug: string }>();

  useEffect(() => {
    actions.fetchById(slug);
    actions.fetchLatestDeliveries(slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStatus = (status: string) => {
    switch (status) {
      case "PAYMENT_WAITING":
        return "접수전";
      case "READY":
        return "배송준비";
      case "PICKUP":
        return "배송중";
      case "DELIVERED":
        return "배송완료";
      default:
        throw new Error("잘못된 배송상태값입니다.");
    }
  };

  const renderCreatedAt = (createdAt: undefined | Date) => {
    return formatDate(getDateByTimeZone(createdAt), "yyyy/MM/dd HH:mm");
  };

  return (
    <Container>
      <Row>
        <Title>회원 조회</Title>
      </Row>
      <Card>
        <Card.Header as="h5">회원 상세 정보</Card.Header>
        <Card.Body>
          <Table bordered>
            <tbody>
              <tr>
                <th>이메일</th>
                <td>{state.userDetail.email}</td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{state.userDetail.name}</td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>{state.userDetail.contact}</td>
              </tr>
              <tr>
                <th>기본주소</th>
                <td>{state.userDetail.defaultAddress}</td>
              </tr>
              <tr>
                <th>상세주소</th>
                <td>{state.userDetail.defaultAddressDetail}</td>
              </tr>
              <tr>
                <th>우편번호</th>
                <td>{state.userDetail.defaultPostCode}</td>
              </tr>
              <tr>
                <th>접속 기기 정보</th>
                <td>{state.userDetail.etc}</td>
              </tr>
              <tr>
                <th>가입일</th>
                <td>
                  {formatDate(
                    getDateByTimeZone(state.userDetail.createdAt),
                    "yyyy/MM/dd HH:mm"
                  )}
                </td>
              </tr>
              <tr>
                <th>로그인 일시</th>
                <td>
                  {state.userDetail.loggedAt === null
                    ? ""
                    : formatDate(
                        getDateByTimeZone(state.userDetail.loggedAt),
                        "yyyy/MM/dd HH:mm"
                      )}
                </td>
              </tr>
              <tr>
                <th>탈퇴 여부</th>
                <td>{state.userDetail.deleted ? "탈퇴" : ""}</td>
              </tr>
              <tr>
                <th>탈퇴 일시</th>
                <td>
                  {state.userDetail.deletedAt === null
                    ? ""
                    : formatDate(
                        getDateByTimeZone(state.userDetail.deletedAt),
                        "yyyy/MM/dd HH:mm"
                      )}
                </td>
              </tr>
            </tbody>
          </Table>

          {state.latestDeliveries.length !== 0 && (
            <>
              <SubTitle>최근 택배 신청 목록</SubTitle>
              <Table bordered>
                <thead>
                  <tr>
                    <th>예약번호</th>
                    <th>배송상태</th>
                    <th>받는분 이름(연락처)</th>
                    <th>신청일</th>
                  </tr>
                </thead>
                <tbody>
                  {state.latestDeliveries.map((d) => (
                    <tr key={d.id}>
                      <td>
                        <Link to={`/deliveries/${d.id}`}>
                          {d.reservationNumber}
                        </Link>
                      </td>
                      <td>{renderStatus(d.status)}</td>
                      <td>
                        {d.receiverAddress.receiverMobile
                          ? `${d.receiverAddress.receiverName} (${d.receiverAddress.receiverMobile})`
                          : d.receiverAddress.receiverName}
                      </td>
                      <td>{renderCreatedAt(d.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <Link to="/users">목록으로 돌아가기</Link>
        </Card.Footer>
      </Card>
    </Container>
  );
};
