import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default () => {
  return (
    <Row>
      <Col>
        <div>작업중</div>
      </Col>
    </Row>
  );
};
