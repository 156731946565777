import React, { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import styled from "@emotion/styled";
import { DropzoneProps } from "@/Modals/ImageUploadModal";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Self = styled.div``;

type File = {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  path: string;
  size: number;
  type: string;
  webkitRelativePath: string;
  arrayBuffer: () => Promise<ArrayBuffer>;
  slice: () => Blob;
  stream: () => ReadableStream<any>;
  text: () => Promise<string>;
};

export default memo(({ accept = "", onUpload }: DropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = (e: any) => {
          onUpload(e.target.result);
        };

        reader.readAsBinaryString(file);
      });
    },
    [onUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({ accept, onDrop });
  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...activeStyle,
      ...acceptStyle,
      ...rejectStyle,
    }),
    []
  );

  return (
    <Self {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <p>파일을 올려놓거나 선택해주세요.</p>
    </Self>
  );
});
